import { QuestionType } from './Types'
import { z } from 'zod'

export const BASE_URL = process.env.REACT_APP_BASE_URL?.toString()
export const ADMIN_API_URL = BASE_URL + '/api/admin'
export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false
// Populated
export const COMMIT_DATE = process.env.REACT_APP_COMMIT_DATE || '--'
export const BUILD_DATE = process.env.REACT_APP_BUILD_DATE || '--'
export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH || '--'

export const VERSION = process.env.REACT_APP_CURRENT_VERSION || '--'

export const MANUAL_VERSION = '1.2.0'

console.log('constants > COMMIT_DATE = ', COMMIT_DATE)
console.log('constants > BUILD_DATE = ', BUILD_DATE)
console.log('constants >  COMMIT_HASH = ', COMMIT_HASH)
console.log('constants >  VERSION = ', VERSION)

export const COLOR_PRIMARY = '#557372'

// Only supported question types should appear in UI
// FIXME: we have duplicate definitions of question types. Here and in QuestionType enum in Types.ts
export const QUESTION_TYPES: QuestionType[] = [
  // 'text',
  // 'checkbox',
  'radio',
  // 'number',
  'info',
  // 'bmi',
  // 'phonenumber',
  // 'name',
  // 'gender',
  // 'birthdate',
  // 'nprs5',
  'nprs11pt5l',
  'nprs11pt2l',
  // 'yesno',
]

export const LANGUAGE_LABELS = {
  en: 'English',
  'en-us': 'English (United States)',
  'es-us': 'Spanish (United States)',
  da: 'Danish',
  yue: 'Cantonese',
  fr: 'French',
  ro: 'Romanian',
  'ro-md': 'Romanian (Republic of Moldova)',
  cs: 'Czech',
  pl: 'Polish',
  ru: 'Russian',
}

export const BODY_PART_LABELS = {
  ABDOMEN_LEFT: 'Left abdomen',
  ABDOMEN_RIGHT: 'Right abdomen',
  ABDOMEN_UPPER: 'Upper abdomen',
  ABDOMEN_LOWER: 'Lower abdomen',
  ANKLE_LEFT: 'Left ankle',
  ANKLE_RIGHT: 'Right ankle',
  ARM_LEFT: 'Left arm',
  ARM_RIGHT: 'Right arm',
  BACK: 'Back',
  FOOT_LEFT: 'Left foot',
  FOOT_RIGHT: 'Right foot',
  HAND_LEFT: 'Left hand',
  HAND_RIGHT: 'Right hand',
  HEAD: 'Head',
  HIP_LEFT: 'Left hip',
  HIP_RIGHT: 'Right hip',
  INDEX_KNEE: 'Index knee',
  KNEE_LEFT: 'Left knee',
  KNEE_RIGHT: 'Right knee',
  LEG_LEFT: 'Left leg',
  LEG_RIGHT: 'Right leg',
  SHOULDER_LEFT: 'Left shoulder',
  SHOULDER_RIGHT: 'Right shoulder',
  WRIST_LEFT: 'Left wrist',
  WRIST_RIGHT: 'Right wrist',
} as const

export type PageType = {
  title: string
  to: string
  path: string
  visible: boolean
  gotoPage?: (...rest) => string
}

export const PROJECT_PAGE: PageType = { title: 'Projects', path: `project`, to: `/project`, visible: true }

export const PROJECT_PAGE_VIEW: PageType = {
  title: 'Projects',
  path: `/project/:projectId/*`,
  to: `/project/:projectId`,
  visible: true,
  gotoPage: (id) => {
    return `/project/` + id
  },
}

export const QUESTIONNAIRE_PAGE_EDIT: PageType = {
  title: 'Questionnaire',
  path: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit`,
  to: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit`,
  visible: true,
}

export const QUESTIONNAIRE_PAGE_FLOW: PageType = {
  title: 'Questionnaire',
  path: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit/flow`,
  to: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/edit/flow`,
  visible: true,
}

export const QUESTIONNAIRE_PAGE_STATS: PageType = {
  title: 'Questionnaire',
  path: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/stats`,
  to: PROJECT_PAGE_VIEW.to + `/questionnaire/:questionnaireId/stats`,
  visible: true,
}

export const QUESTIONNAIRE_PAGE_TEST: PageType = {
  title: 'Questionnaire',
  path: `/questionnaire/:questionnaireId/test/:language`,
  to: `/questionnaire/:questionnaireId/test/:language`,
  visible: true,
}

export const PROJECT_PAGE_CREATE: PageType = {
  title: 'Projects',
  to: PROJECT_PAGE.to + `/create`,
  path: PROJECT_PAGE.path + `/create`,
  visible: false,
}

export const LOGIN = { title: 'Login', to: `/`, base: '/', visible: false }
export const LOGIN_NEW_PASSWORD = {
  title: 'New-password',
  path: `/new-password`,
  to: '/login/new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD_SUBMIT = {
  title: 'submit-new-password',
  to: `/login/submit-new-password`,
  path: '/submit-new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD = {
  title: 'forgot password',
  path: 'forgot-password',
  to: `/login/forgot-password`,
  visible: false,
}

export const LOGIN_MFA_CODE = { title: 'mfa', path: '/mfa', to: `/login/mfa`, visible: false }

export const PAGES = [PROJECT_PAGE]

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
export const COGNITO_API_SIGNUP_ERRORS = {
  AliasExistsException: 'AliasExistsException',
  CodeMismatchException: 'CodeMismatchException',
  ExpiredCodeException: 'ExpiredCodeException',
  ForbiddenException: 'ForbiddenException',
  InternalErrorException: 'InternalErrorException',
  InvalidLambdaResponseException: 'InvalidLambdaResponseException',
  InvalidParameterException: 'InvalidParameterException',
  LimitExceededException: 'LimitExceededException',
  NotAuthorizedException: 'NotAuthorizedException',
  ResourceNotFoundException: 'ResourceNotFoundException',
  TooManyFailedAttemptsException: 'TooManyFailedAttemptsException',
  TooManyRequestsException: 'TooManyRequestsException',
  UnexpectedLambdaException: 'UnexpectedLambdaException',
  UserLambdaValidationException: 'UserLambdaValidationException',
  UserNotFoundException: 'UserNotFoundException',
} as const

// ZOD
export const visitWindowZodSchema = z.object({ duration: z.number(), offset: z.number() })
